import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { stringify } from 'query-string';
import { Subscription } from 'rxjs';
import { BusinessUnitClient, BusinessUnitFilterBy, BusinessUnitFilterByFilterBy, BusinessUnitSearchQuery, BusinessUnitSortBy, BusinessUnitSortBySortBy, BusinessUnitViewModel } from 'src/app/apis/customer-care-api.generated';
import { ICommonPaginatorEvent } from 'src/app/shared/widgets/paginator/paginator.component';
import { SnackBarService } from 'src/app/shared/widgets/snack-bar/snack-bar.service';
import * as queryString from "query-string";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export interface IBusinessUnitSearchFilters {
  code: string;
  name: string;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-business-unit-lookup',
  templateUrl: './business-unit-lookup.component.html',
  styleUrls: ['./business-unit-lookup.component.scss']
})
export class BusinessUnitLookupComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private buClient: BusinessUnitClient) {

    this.businessUnitGroup = this.fb.group({
      code: new FormControl(),
      name: new FormControl(),
    });

    this.setDefaultSort();

    this.initializeFromQueryString();
  }

  private pageIndex: number = 1;
  private pageSize: number = 10;
  private totalRequests: number;
  private filterBy: BusinessUnitFilterByFilterBy[] = [];
  private sortBy: BusinessUnitSortBySortBy[] = [];
  private isInitialized: boolean;
  private subscription: Subscription = new Subscription();;

  businessUnitGroup: FormGroup;
  isLoading: boolean = false;
  businessUnits: BusinessUnitViewModel[] | null | undefined;
  isAllowedToCreateBusinessUnit: boolean = false;


  ngOnInit() {
    this.onSearchClick();
    this.subscription
      .add(
        this.authService.currentUser
          .subscribe(user => {
            if(user) {
              this.isAllowedToCreateBusinessUnit = user.isAllowedToCreateBusinessUnit;
              if(!user.isAllowedToViewBusinessUnits)
              {
                this.translateService.get('no-access').subscribe((data:string) => { this.snackBarService.ShowError(data) });
              }
            }
          })
      );
  }

  get isBusinessUnitGroupValid() {
    return this.businessUnitGroup.valid;
  }

  paginate(e: ICommonPaginatorEvent): void {
    if (this.isInitialized) {
      if (this.pageIndex == e.pageIndex) {
        this.pageIndex = 1;
      } else {
        this.pageIndex = e.pageIndex;
      }

      this.pageSize = e.pageSize;
      this.createQueryStringFilters();
    }
  }

  onSearchClick() {
    this.pageIndex = 1;

    this.createQueryStringFilters();
  }

  onClearFiltersClicked() {
    this.pageIndex = 1;

    this.businessUnitGroup.reset();

    this.createQueryStringFilters();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getYesNo(isTrue: boolean): string {
    return isTrue != null ? 
      (isTrue
        ? this.translateService.instant('yes')
        : this.translateService.instant('no'))
      : this.translateService.instant('yes');
  }

  private loadRequests() {
    this.isLoading = true;
    var searchQuery = new BusinessUnitSearchQuery();
    searchQuery.filterBy = this.filterBy.length == 0 ? undefined : this.filterBy;
    searchQuery.page = this.pageIndex;
    searchQuery.pageSize = this.pageSize;
    searchQuery.sortBy = this.sortBy;

    this.subscription
      .add(
        this.buClient.searchBusinessUnit(searchQuery)
          .subscribe(result => {
            this.businessUnits = result.records;
            this.totalRequests = result.totals[0].value;

            setTimeout(() => {
              this.isInitialized = true;
            }, 2000);
            this.isLoading = false;
          },
            (error) => {
              try {
                console.error(error);
                this.snackBarService.ShowError(error);
               }
               catch { }
               this.isLoading = false;
            })
      );
  }

  private filterRequests() {
    this.filterBy = [];

    /*---------------------- FILTER -------------------*/
    if (this.businessUnitGroup.value.code) {

      const filterByCode = new BusinessUnitFilterByFilterBy({
        property: BusinessUnitFilterBy.Code,
        contains: this.businessUnitGroup.value.code,
      });

      this.filterBy.push(filterByCode);
    }

    if (this.businessUnitGroup.value.name) {
      const filterByName = new BusinessUnitFilterByFilterBy({
        property: BusinessUnitFilterBy.Name,
        contains: this.businessUnitGroup.value.name,
      });

      this.filterBy.push(filterByName);
    }

    this.loadRequests();
  }

  private createQueryStringFilters() {
    let requestFilterQueryString: any = {};

    /*---------------------- CODE FILTER -------------------*/
    if (this.businessUnitGroup.value.code) {
      requestFilterQueryString.code = this.businessUnitGroup.value.code;
    }

    /*---------------------- NAME FILTER -------------------*/
    if (this.businessUnitGroup.value.name) {
      requestFilterQueryString.name = this.businessUnitGroup.value.name;
    }

    /*----------------------------------- PAGE NUMBER ------------------------------------------*/
    requestFilterQueryString.pageIndex = this.pageIndex;

    /*----------------------------------- PAGE SIZE --------------------------------------------*/
    requestFilterQueryString.pageSize = this.pageSize;

    const stringifiedAgreementQueryString = stringify(
      requestFilterQueryString,
      { arrayFormat: "comma" }
    );

    this.router.navigateByUrl(`admin/business_units?${stringifiedAgreementQueryString}`);

    this.filterRequests();
  }

  private initializeFromQueryString() {
    const requestParsedFilters: IBusinessUnitSearchFilters = (queryString.parse(
      location.search,
      { arrayFormat: "comma" }
    ) as any) as IBusinessUnitSearchFilters;

    this.businessUnitGroup.patchValue({
      code: requestParsedFilters.code,
      name: requestParsedFilters.name
    });

    this.pageIndex = requestParsedFilters.pageIndex
      ? requestParsedFilters.pageIndex
      : 1;

    this.pageSize = requestParsedFilters.pageSize
      ? requestParsedFilters.pageSize
      : 10;

    this.filterRequests();
  }

  private setDefaultSort() {
    this.sortBy = [];
    /*-----------DEFAULT SORT BY ------------*/
    const sortByName = new BusinessUnitSortBySortBy({
      property: BusinessUnitSortBy.Code,
      descendingOrder: false,
    });

    this.sortBy.push(sortByName);
    /*-----------DEFAULT SORT BY ------------*/
  }
}
