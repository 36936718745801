import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MyAccountAccountLookupViewModel,
  MyAccountAccountsClient,
  MyAccountInvoicesViewModel,
  MyAccountLookupBy,
} from "src/app/apis/customer-care-api.generated";
import { SnackBarService } from "src/app/shared/widgets/snack-bar/snack-bar.service";

@Component({
  selector: "app-my-account-user-lookup",
  templateUrl: "./my-account-account-lookup.component.html",
  styleUrls: ["./my-account-account-lookup.component.scss"],
})
export class MyAccountAccountLookupComponent implements OnInit {
  constructor(
    private myAccountAccountsClient: MyAccountAccountsClient,
    private snackBarService: SnackBarService,
    private vps: ViewportScroller
  ) { }

  isLoading = false;
  accountLoaded = false;
  scrolledAfterLoad = true;

  accountNumber = new FormControl("");
  invoiceNumber = new FormControl("");
  siteNumber = new FormControl("");

  toolDescription = "This tool is meant to find the account details related to the account, invoice or site that is entered. \n This tool will show the Account details and then list the Invoices, Services, Sites and/or Users related to the lookup query."

  account: MyAccountAccountLookupViewModel;

  get myAccountAccountFound() {
    return (this.account &&
      (this.account.usersFromAccount ||
        this.account.usersFromAccount.length ||
        this.account.usersFromSite ||
        this.account.usersFromSite.length ||
        this.account.sites ||
        this.account.sites.length ||
        this.account.invoices ||
        this.account.invoices.length ||
        this.account.services ||
        this.account.services.length)
    );
  }

  get isValidAccountNumber() {
    return (this.accountNumber.value || "").trim().length > 0;
  }

  get isValidInvoiceNumber() {
    return (this.invoiceNumber.value || "").trim().length > 0;
  }

  get isValidSiteNumber() {
    return (this.siteNumber.value || "").trim().length > 0;
  }
  
  get isValidLengthAccountNumber()
  {
    return (this.accountNumber.value || "").trim().length > 0 && ( (this.accountNumber.value || "").trim().length < 9 ||  (this.accountNumber.value || "").trim().length > 9 );
  }

  get isValidSearch() {
    // only valid if exactly one of the values is valid

    if (!this.isValidAccountNumber && !this.isValidInvoiceNumber && this.isValidSiteNumber)
    {
      // valid site number
      return true;
    }
    if (!this.isValidAccountNumber && !this.isValidSiteNumber && this.isValidInvoiceNumber)
    {
      // valid invoice number
      return true;
    }
    if (!this.isValidInvoiceNumber && !this.isValidSiteNumber && this.isValidAccountNumber)
    {
      // valid account number
      return true;
    }
    return false;
  }

  search() {
    this.account = null;
    this.isLoading = true;

    var number = "";
    var lookupBy : MyAccountLookupBy;

    if (this.isValidInvoiceNumber)
    {
      number = this.invoiceNumber.value.trim();
      lookupBy = MyAccountLookupBy.InvoiceNumber;
    }
    else if (this.isValidAccountNumber)
    {
      number = this.accountNumber.value.trim();
      lookupBy = MyAccountLookupBy.AccountNumber;
    }
    else if (this.isValidSiteNumber)
    {
      number = this.siteNumber.value.trim();
      lookupBy = MyAccountLookupBy.SiteNumber;
    }
    else
    {
      this.isLoading = false;
          this.snackBarService.ShowError(
            "Please enter a value for one of the fields."
          );
    }

    this.myAccountAccountsClient
      .searchForMyAccountAccounts(number, lookupBy)
      .subscribe(
        (x) => {
          this.account = x.result;
          this.sortInvoices();
          this.accountLoaded = true;
          this.scrolledAfterLoad = false;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.ShowError(
            "Search for MyAccount Account Error: " + error
          );
        }
      );
  }

  sortInvoices() {
    if (this.myAccountAccountFound) {
      var unsortedInvoices = this.account.invoices;
      unsortedInvoices.sort((a: MyAccountInvoicesViewModel, b: MyAccountInvoicesViewModel) => (
        (new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime())));
      this.account.invoices = unsortedInvoices;
    }

  }

  scrollToBottom() {
    if (!this.isValidAccountNumber && !this.scrolledAfterLoad) {
      this.vps.scrollToAnchor('invoices');
    }
    this.scrolledAfterLoad = true;
  }

  ngOnInit() { }
}
