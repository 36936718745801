import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { API_BASE_URL } from './app/apis/customer-care-api.generated';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppSettings } from './app/shared/models/app-settings';

if (environment.production) {
  enableProdMode();
}

window.fetch('assets/appsettings.json')
.then((r) => r.json())
.then((s: AppSettings) => {
  platformBrowserDynamic([{ provide: AppSettings, useValue: s }, { provide: API_BASE_URL , useValue: s.api.baseUri }])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));  
});
